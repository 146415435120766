import { toTypedSchema } from "@vee-validate/yup"
import { object, string } from "yup"

export const CREATE_STREAM = toTypedSchema(
  object({
    name: string()
      .label("Stream name")
      .required("Please provide a name for your stream"),
    source_id: string().label("Source").required("Please select a source"),
    destination_id: string()
      .label("Destination")
      .required("Please select a destination"),
  })
)

export const UPDATE_STREAM = toTypedSchema(
  object({
    name: string()
      .label("Stream name")
      .required("Please provide a name for your stream"),
  })
)
